<template>
  <div class="userManagement-container">
    <vab-query-form>
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="用户昵称">
          <el-input
            v-model.trim="queryForm.nickname"
            placeholder="请输入昵称"
            clearable
          />
        </el-form-item>
        <el-form-item label="用户id">
          <el-input
            v-model.trim="queryForm.code"
            placeholder="请输入ID"
            clearable
          />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model.trim="queryForm.mobile"
            placeholder="请输入手机号"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="queryData">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 多选 -->
      <!-- <el-table-column show-overflow-tooltip type="selection"></el-table-column> -->
      <!-- 用户id -->
      <el-table-column

        show-overflow-tooltip
        prop="id"
        label="id"

      ></el-table-column>

      <!-- 用户昵称 -->
      <el-table-column
        show-overflow-tooltip
        prop="nickname"
        label="用户昵称"
      ></el-table-column>
      <!-- 用户手机号 -->
      <el-table-column
        show-overflow-tooltip
        prop="mobile"
        label="手机号"
      ></el-table-column>
      <!-- 用户ID -->
      <el-table-column
        show-overflow-tooltip
        prop="code"
        label="用户ID"
      ></el-table-column>
      <!-- 用户头像 -->
      <el-table-column show-overflow-tooltip label="头像">
        <template #default="{ row }">
          <el-image
            v-if="imgShow"
            :preview-src-list="imageList"
            :src="row.pic"
          ></el-image>
        </template>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        show-overflow-tooltip
        prop="add_time"
        label="创建时间"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="操作面板" width="250">
        <template #default="{ row }">
          <el-button type="" size="mini"  @click="handledetail(row)">详情</el-button>
          <el-button type="primary" size="mini" @click="handleEdit(row)">编辑</el-button>
          <el-button type="danger"  size="mini" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="isShowPage"
      background
      :current-page="queryForm.page"
      :page-size="queryForm.limit"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <edit ref="edit" @fetch-data="fetchData"></edit>
  </div>
</template>

<script>
  import Edit from './components/UserManagementEdit'
  import { memberList, memberDel } from '@/api/xgcApi'
  export default {
    name: 'UserManagement',
    components: { Edit },
    data() {
      return {
        imageList: [],
        imgShow: true,
        list: null,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 10,
        },
        isShowPage: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      // 编辑用户列表
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      // 用户信息详情
      handledetail(row) {
        this.$router.push({ path: './user', query: { id: row.id } })
      },
      // 删除用户列表
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await memberDel(row.id)
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询
      queryData() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 请求列表数据
      async fetchData() {
        this.listLoading = true
        const { data } = await memberList(this.queryForm)
        this.list = data.list
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })
        this.imageList = imageList
        if (this.list.length > 0) {
          this.isShowPage = true
        } else {
          this.isShowPage = false
        }
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>
