<template>
  <div class="table-container">
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        align="center"
        min-width="100"
        show-overflow-tooltip
        label="序号"
        width="95"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <!-- 编码 -->
      <el-table-column
        show-overflow-tooltip
        prop="code"
        label="编码"
        align="center"
        min-width="100"
      ></el-table-column>

      <!-- 邮编 -->
      <el-table-column
        show-overflow-tooltip
        prop="ems"
        label="邮编"
        align="center"
        min-width="100"
      ></el-table-column>
      <!-- 仓库名 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="仓库名"
        align="center"
        min-width="100"
      ></el-table-column>
      <!-- 省市区 -->
      <el-table-column
        show-overflow-tooltip
        prop="city"
        label="省市区"
        align="center"
        min-width="200"
      ></el-table-column>
      <!-- 地址 -->
      <el-table-column
        show-overflow-tooltip
        prop="address"
        label="地址"
        align="center"
        min-width="200"
      ></el-table-column>

      <!-- 电话 -->
      <el-table-column
        show-overflow-tooltip
        prop="mobile"
        label="电话"
        min-width="120"
        align="center"
      ></el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        align="center"
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        min-width="200"
        sortable
      ></el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        show-overflow-tooltip
        label="操作面板"
        width="180px"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <manage-store ref="edit" @fetch-data="getnewlist"></manage-store>
  </div>
</template>

<script>
  import { warehouseList, deleteList } from '@/api/xgcApi'
  import manageStore from '../components/manageStore'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageStore,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          name: '',
        },
        value1: '',
        value: '',
        height:'79vh'
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        await warehouseList(this.queryForm).then((res) => {
          var data = res.data
          this.listLoading = false
          this.list = data.list
          this.total = data.count
        })
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
