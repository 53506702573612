<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="代理名称" prop="name">
        <el-input v-model.trim="form.name" :readonly="true"></el-input>
      </el-form-item>

      <el-form-item label="微信号码" prop="wx">
        <el-input v-model.trim="form.wx" :readonly="true"></el-input>
      </el-form-item>

      <el-form-item label="电话号码" prop="phone">
        <el-input v-model.trim="form.phone" :readonly="true"></el-input>
      </el-form-item>

      <el-form-item label="创建时间" prop="add_time">
        <el-input v-model.trim="form.add_time" :readonly="true"></el-input>
      </el-form-item>

      <el-form-item label="申请原因" prop="reason">
        <el-input
          v-model.trim="form.reason"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10 }"
          :readonly="true"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { ProxyDetail } from '@/api/Proxy'
  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        dialogFormVisible: false,
        form: {},
        //设置标题
        title: '申请代理详情',
      }
    },

    created() {},
    methods: {
      // 获取详情数据
      async showEdit(row) {
        //请求接口
        const { data } = await ProxyDetail({ id: row.id })
        this.form = data
        this.dialogFormVisible = true
      },

      // 点击关闭弹窗
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style scoped>
  .black {
    font-weight: bold;
    color: #000000;
  }
  .red {
    color: #ff0000;
  }
  .xinxi {
    border: 1px solid #dcdfe6;
    margin-bottom: 5px;
    padding: 10px;
    box-sizing: border-box;
  }
</style>
