<template>
  <div v-loading="listLoading" class="table-container">
    <vab-query-form>
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item style="float: right">
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="handleAdd(true)"
          >
            添加侧边栏
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>
    <!-- -->
    <el-table
      default-expand-all
      :data="list"
      :height="height"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      :tree-props="{ children: 'children' }"
    >
      <el-table-column
        prop="title"
        label="标题"
        min-width="180"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="path"
        label="path"
        min-width="180"
      ></el-table-column>
      <el-table-column align="center" prop="icon" label="图标" min-width="50">
        <template slot-scope="{ row }">
          <i
            style="font-size: 32px"
            :class="row.meta.icon ? row.meta.icon : ''"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="orderNum"
        label="排序"
        min-width="50"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="hidden"
        label="图标"
        min-width="180"
      >
        <template slot-scope="{ row }">
          {{ row.hidden ? '隐藏' : '显示' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleAdd(false, row)">修改</el-button>
          <el-button type="text" @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto" :background="background"
        :current-page="queryForm.page" :layout="layout" :page-sizes="[20, 40, 60, 100]" :page-size="queryForm.limit"
        :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination> -->
    <!-- 添加修改侧边栏对话框 -->
    <el-dialog
      :title="IsAdd ? '添加侧边栏' : '修改侧边栏'"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form
        v-if="dialogVisible"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入title"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="ruleForm.link" placeholder="请输入path"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="隐藏" prop="hidden">
          <el-radio-group v-model="ruleForm.hidden">
            <el-radio :label="1">隐藏</el-radio>
            <el-radio :label="0">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="父级ID" prop="pid">
          <el-select v-model="ruleForm.pid" placeholder="请选择活动区域">
            <el-option
              v-for="(item, index) in list"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
            <el-option label="顶级" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" prop="pic">
          <el-input v-model="ruleForm.pic" placeholder="请输入icon"></el-input>
        </el-form-item>
        <el-form-item label="文件路径" prop="component">
          <el-input
            v-model="ruleForm.component"
            placeholder="请输入component"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            立即创建
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    Auth_list,
    Auth_Add,
    Auth_detail,
    Auth_update,
    Auth_del,
  } from '@/api/xgcApi'

  export default {
    name: 'ComprehensiveTable',
    components: {},
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        dialogVisible: false, //添加侧边栏对话框
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          link: [{ required: true, message: '请输入链接', trigger: 'blur' }],
          sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
          hidden: [
            { required: true, message: '请选择是否隐藏', trigger: 'blur' },
          ],
          component: [
            { required: true, message: '请输入文件路径', trigger: 'blur' },
          ],
          pid: [{ required: true, message: '请选择父级ID', trigger: 'blur' }],
        }, //表单验证规则
        ruleForm: {}, //侧边栏详情
        gidList: [], //用户组列表
        IsAdd: true, //是否是添加 true添加 false修改
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        height: '73vh',
      }
    },
    computed: {},
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      // 删除
      handleDel(row) {
        this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            Auth_del({ id: row.id }).then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.fetchData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.ruleForm)
            if (this.IsAdd) {
              Auth_Add(this.ruleForm).then((res) => {
                this.$message({
                  type: 'success',
                  message: '添加成功',
                })
                this.fetchData()
                this.dialogVisible = false
              })
            } else {
              Auth_update(this.ruleForm).then((res) => {
                this.$message({
                  type: 'success',
                  message: '修改成功',
                })
                this.fetchData()
                this.dialogVisible = false
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      fetchData() {
        // 获取用户组列表
        var data = {
          page: 1, //页码
          limit: 50, //条数
        }
        Auth_list(data)
          .then((res) => {
            this.listLoading = false
            this.list = res.data
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      handleAdd(type, row = {}) {
        // 添加侧边
        this.listLoading = true
        this.IsAdd = type
        this.ruleForm = {}
        if (type) {
          // 新增
          this.dialogVisible = true
        } else {
          // 修改
          Auth_detail({ id: row.id })
            .then((res) => {
              this.dialogVisible = true
              this.ruleForm = res.data
              this.listLoading = false
            })
            .catch((err) => {
              this.listLoading = false
            })
        }
      },
      // 关闭添加修改对话框
      handleClose() {
        this.dialogVisible = false
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
