<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-right-panel class="float-right">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加轮播图
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      class="table"
      :data="list"
      :height="height"
      :element-loading-text="elementLoadingText"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        show-overflow-tooltip
        label="排序"
        prop="sort"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="图片" align="center">
        <template #default="{ row }">
          <el-image :preview-src-list="imageList" :src="row.file"></el-image>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="轮播图标题"
        prop="name"
        width="300"
        align="center"
      ></el-table-column>

      <el-table-column label="轮播图类型" align="center" prop="type">
        <template #default="{ row }">
          <div v-if="row.type == 0">韩到中</div>
          <div v-if="row.type == 1" id="">中到韩</div>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="300"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="300px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            type="text"
            style="color: #ff0000; margin-left: 20px"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <!-- <table-edit ref="edit"></table-edit> -->
    <banner-edit ref="edit" @fetchDdata="flush"></banner-edit>
  </div>
</template>

<script>
  import { getBannerList, deleteList } from '@/api/banner'
  import bannerEdit from '../components/manageBanner.vue'
  export default {
    name: 'ComprehensiveTable',
    components: {
      bannerEdit,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '74vh',
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          name: '',
          page: 1,
          limit: 20,
        },
        value1: '',
        value: '',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteList({ id: row.id })
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await getBannerList(this.queryForm)
        this.list = data.list
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.file)
        })
        this.imageList = imageList
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
      flush() {
        this.fetchData()
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .float-right {
    float: right;
  }
  .table {
    margin-top: 30px;
  }
</style>
