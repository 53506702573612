<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="form.name"
          autocomplete="off"
          placeholder="请输入名称"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="邀请码" prop="code">
        <el-input
          v-model="form.code"
          autocomplete="off"
          placeholder="请输入邀请码"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="韩语名称" prop="kor_name">
        <el-input
          v-model.trim="form.kor_name"
          autocomplete="off"
          placeholder="请输入韩语名称"
        ></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="上级ID" prop="p_id">
        <el-input
          v-model.trim="form.p_id"
          autocomplete="off"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="文章内容" prop="content">
        <el-input
          v-model.trim="form.content"
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          autocomplete="off"
        ></el-input>
      </el-form-item> -->
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    updateInvitation,
    InvitationDetail,
    addList,
  } from '@/api/invitation.js'

  export default {
    name: 'InvitationEdit',
    data() {
      return {
        //判断是添加还是修改
        isUp: '',
        form: {},
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入名称' },
            { max: 15, message: '名称最多只能输入15个字符', trigger: 'blur' },
          ],
          code: [
            { required: true, trigger: 'blur', message: '请输入邀请码' },
            { max: 15, message: '邀请码最多只能输入15个字符', trigger: 'blur' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '添加邀请码'
          this.isUp = false
        } else {
          this.title = '编辑邀请码'
          this.isUp = true
          this.form.id = row.id
          const { data } = await InvitationDetail(this.form)
          this.form = data
          // this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      async save() {
        //判断是保存还是修改 isUp为true 则为修改 为false则为添加。
        if (this.isUp) {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const { msg } = await updateInvitation(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              // this.form = this.$options.data().form
            } else {
              return false
            }
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const { msg } = await addList(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              return false
            }
          })
        }
      },
    },
  }
</script>
