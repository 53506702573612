<template>
  <div>
    <div class="table-container">
      <vab-query-form>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.code"
              placeholder="请输入订单编号"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form>

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        :element-loading-text="elementLoadingText"
        :height="height"
        border
        @sort-change="tableSortChange"
        style="width: 100%"
      >
        <!-- 商品图片 -->
        <el-table-column align="center" show-overflow-tooltip label="商品图片">
          <template #default="{ row }">
            <el-image :preview-src-list="imageList" :src="row.pic"></el-image>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="code"
          label="订单编号"
          width="350"
        ></el-table-column>

        <!-- 包裹状态 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="status_name"
          label="包裹状态"
        ></el-table-column>
        <!-- 订单金额 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="price"
          label="订单金额"
        ></el-table-column>
        <!-- 订单重量 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="weight"
          label="订单重量"
        ></el-table-column>

        <el-table-column
          align="center"
          show-overflow-tooltip
          label="创建时间"
          prop="add_time"
          width="170"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="操作面板"
          width="180px"
          fixed="right"
          align="center"
        >
          <template #default="{ row }">
            <el-button size="mini" @click="handleEdit(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :background="background"
        :current-page="queryForm.page"
        :layout="layout"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <manage-parcel-tl ref="edit" @fetch-data="getnewlist"></manage-parcel-tl>
    </div>
  </div>
</template>

<script>
  import {
    orderList,
    goodsDel,
    orderStatus,
    orderNotice,
    oexcelOut,
    pullingCheck,
    orderClaimListZdh,
  } from '@/api/xgcApi'
  import { Order_printStatus, Order_printCheck } from '@/api/print'
  import manageParcelTl from '../components/manageParcelTl'
  import parcelDetails from '../components/parcelDetails'
  import { baseURL_print, getUploadUrl } from '@/config'
  import { number } from 'echarts'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageParcelTl,
      parcelDetails,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '74vh',
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        remindCount: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 刷新请求数据
      getnewlist() {
        this.fetchData()
      },
      // 看图片
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },

      // 点击修改编辑
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit1'].showEdit(row)
      },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 请求列表数据
      async fetchData() {
        this.listLoading = true
        orderClaimListZdh(this.queryForm).then(({ data }) => {
          this.list = data.list
          this.remindCount = data.statusCount
          this.total = data.count
          const imageList = []
          data.list.forEach((item, index) => {
            imageList.push(item.pic)
          })
          this.imageList = imageList
          this.listLoading = false
        })
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
  .demo-tabs .custom-tabs-label span {
    vertical-align: super;
    margin-left: 4px;
  }
  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 12px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item {
    width: 200px; /* 设置宽度为200px */
  }
  .el-badge {
    width: 55px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }
  .demo-table-expand {
    font-size: 0;
    padding-left: 150px;
    box-sizing: border-box;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }
</style>
