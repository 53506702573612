<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-left-panel>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="姓名">
            <el-input
              v-model="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>

          <el-form-item label="电话号">
            <el-input
              v-model="queryForm.phone"
              clearable
              placeholder="请输入电话号"
            />
          </el-form-item>

          <el-form-item label="微信号码">
            <el-input
              v-model="queryForm.wx"
              clearable
              placeholder="请输入微信号码"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
    >
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="代理名称"
        align="center"
        width="200"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="reason"
        label="申请原因"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="wx"
        label="微信号码"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="phone"
        label="电话号"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="340"
        sortable
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="150px"
        fixed="right"
        align="center"
      >
        <template #default="{ row }">
          <el-button  type="text" @click="handledetails(row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <proxy-details ref="edit1" @fetch-data="getnewlist"></proxy-details>
  </div>
</template>

<script>
  import { getProxyList, ProxyDetail } from '@/api/Proxy'
  import ProxyDetails from '../components/proxyDetails'
  export default {
    components: {
      ProxyDetails,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '74vh',
        //申请理由列表集合
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        elementLoadingText: '正在加载...',
        //封装请求参数
        queryForm: {
          page: 1,
          limit: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      getnewlist() {
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      //获取列表 初始化页面
      async fetchData() {
        this.listLoading = true
        const { data } = await getProxyList(this.queryForm)
        this.list = data.list
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit1'].showEdit(row)
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
