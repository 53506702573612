<template>
  <!-- 增值服务管理———组件 -->
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="价格" prop="price">
        <el-input
          v-model.trim="form.price"
          autocomplete="off"
          placeholder="请输入"
          @input="handleInputprice"
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item v-if="!isShow" label="状态">
        <el-switch
          v-model="form.is_delete"
          :inactive-value="1"
          :active-value="0"
        ></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { serviceDetail, serviceUpdate, serviceAdd } from '@/api/xgcApi'

  export default {
    name: 'ManageStore',
    data() {
      return {
        form: {
          is_delete: 0,
        },
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          price: [{ required: true, trigger: 'blur', message: '请输入价格' }],
        },
        title: '',
        dialogFormVisible: false,
        isShow: true,
      }
    },
    methods: {
      handleInputprice() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.price = this.form.price
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.isShow = true
          this.title = '编辑'
          const { data } = await serviceDetail({id:row.id})
          this.form = data
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == false) {
              const { msg } = await serviceAdd(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              const { msg } = await serviceUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
