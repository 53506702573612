<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-left-panel>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <!-- 品牌名称 -->
          <el-form-item label="品牌名称" prop="brand_id">
            <el-select
              v-model="queryForm.brand_id"
              filterable
              placeholder="请选择"
              width="500px"
              clearable
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型名称">
            <el-input v-model="queryForm.name"  clearable placeholder="请输入类型名称" />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>

      <vab-query-form-right-panel>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 序号 -->
      <el-table-column
        show-overflow-tooltip
        prop="sort"
        label="序号"
        sortable
        align="center"
        width="150"
      ></el-table-column>

      <!-- 类型名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="类型名称"
        align="center"
      ></el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="340"
        sortable
        align="center"
      ></el-table-column>
      <!-- 操作面板 -->
      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="250px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            type="text"
            style="margin-left: 25px; color: #ff0000"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <manage-types ref="edit" @fetch-data="getnewlist"></manage-types>
  </div>
</template>

<script>
  import { typesList, typesDel } from '@/api/xgcApi'
  import manageTypes from '../components/manageTypes'
  import { getBaList } from '@/api/brand.js'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageTypes,
    },
    data() {
      return {
        height:'74vh',
        brandList: [],
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          name: '',
        },
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.getBrandList()
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await typesDel({ ids: row.id })
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await typesList(this.queryForm)
        this.list = data.list

        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
      async getBrandList() {
        const { data } = await getBaList({ id: '' })
        this.brandList = data
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
