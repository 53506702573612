<template>
  <!-- 分类管理————组件 -->
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 名称 -->
      <el-form-item label="分类名称" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 序号 -->
      <el-form-item v-if="isShow" label="序号" prop="sort">
        <el-input
          v-model.trim="form.sort"
          autocomplete="off"
          placeholder="请输入"
          maxlength="4"
          show-word-limit
          @input="handleInput"
        ></el-input>
      </el-form-item>
      <!-- 上级id -->
      <el-form-item v-if="isShow == false" label="上级id" prop="pid">
        <el-input
          v-model.trim="form.pid"
          autocomplete="off"
          placeholder="请输入"
          maxlength="4"
          show-word-limit
          @input="handleInputPid"
        ></el-input>
      </el-form-item>

      <!-- 创建时间 -->
      <el-form-item v-if="isShow" label="创建时间" prop="add_time">
        <el-input
          v-model.trim="form.add_time"
          autocomplete="off"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { cateDetail, cateUpdate, cateAdd } from '@/api/xgcApi'

  export default {
    name: 'ManageStore',
    data() {
      return {
        form: {},
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          pid: [{ required: true, trigger: 'blur', message: '请输入id' }],
          sort: [
            { required: true, trigger: 'blur', message: '请输入内容' },
            {
              validator: (rule, value, callback) => {
                const reg = /^\d+$/
                if (!reg.test(value) || parseInt(value) > 1000) {
                  callback(new Error('请输入不超过1000的正整数'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
        isShow: true,
      }
    },
    created() {},
    methods: {
      handleInput() {
        // Remove non-numeric characters and leading zeros
        this.form.sort = this.form.sort.replace(/\D/g, '').replace(/^0+/, '')
      },
      handleInputPid() {
        this.form.pid = this.form.pid.replace(/\D/g, '').replace(/^0+/, '')
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.isShow = true
          this.title = '编辑'
          const { data } = await cateDetail({ id: row.id })
          this.form = data
          // this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == false) {
              const { msg } = await cateAdd(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              const { msg } = await cateUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
