<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="80vw"
    height="600px"
    @close="close"
  >
    <!--    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      style="display: flex"
    >
      <el-form-item label="用户ID" prop="code" style="width: 250px">
        <el-input
          v-model.trim="form.code"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 250px" label="用户名" prop="nickname">
        <el-input
          v-model.trim="form.nickname"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item style="width: 250px" label="电话号" prop="mobile">
        <el-input
          v-model.trim="form.mobile"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item style="width: 250px" label="添加时间" prop="add_time">
        <el-input
          v-model.trim="form.add_time"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="头像" prop="pic">
        <el-image
          style="width: 100px; height: 100px"
          :src="imageUrl"
          :preview-src-list="srcList"
        ></el-image>
      </el-form-item>
    </el-form> -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="goBack()">取消</el-button>
      <!-- <el-button type="primary" @click="save">确 定</el-button> -->
    </div>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="a" itemtype="" style="width: 100px">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span style="width: 100px">进口订单</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="未入库" name="b" itemtype="0">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span>出口订单</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>

    <div class="table-container">
      <vab-query-form style="margin: 0">
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.code"
              placeholder="请输入订单编号"
              clearable
            />
          </el-form-item>
          <el-form-item label="运单号">
            <el-input
              v-model="queryForm.express_code"
              placeholder="请输入运单号"
              clearable
            />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form>

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
        border
      >
        <!-- 订单编号 -->
        <el-table-column
          show-overflow-tooltip
          prop="one_code"
          label="订单号"
          width="350"
        ></el-table-column>

        <!--运单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="express_code"
          label="运单号"
        ></el-table-column>
        <!-- 品牌名 -->
        <el-table-column
          show-overflow-tooltip
          prop="brand_name"
          label="品牌名"
        ></el-table-column>

        <!--品牌类型 -->
        <el-table-column
          show-overflow-tooltip
          prop="brand_type_name"
          label="品牌类型"
        ></el-table-column>
        <!--品牌价格 -->
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="品牌价格"
        ></el-table-column>
        <!--数量 -->
        <el-table-column
          show-overflow-tooltip
          prop="num"
          label="数量"
        ></el-table-column>
        <!--重量 -->
        <el-table-column
          show-overflow-tooltip
          prop="weight"
          label="重量"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="pay_time"
          label="支付时间"
        ></el-table-column>
      </el-table>

      <el-pagination
        style="
          position: absolute;
          left: 0;
          right: 0;
          bottom: 20px;
          margin: auto;
        "
        background
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        :layout="layout"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
  import { memberUdate, memberDetail } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'

  export default {
    name: 'userDetails',
    data() {
      return {
        title: '用户详情',

        activeName: 'a',
        id: '',
        form: {},
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
          type: 1,
        },
        dialogFormVisible: false,
        rules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          password: [
            { required: true, trigger: 'blur', message: '请输入密码' },
          ],
          email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
          permissions: [
            { required: true, trigger: 'blur', message: '请选择权限' },
          ],
        },
        imageUrl: '',
        actionUrl: getUploadUrl + '/index/index/file',
        list: null,
        list1: null,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        srcList: [],
      }
    },
    methods: {
      // table，切换方法
      handleClick(tab, event) {
        this.queryForm.page = 1
        if (tab.name == 'a') {
          this.getHdzDetail()
        }

        if (tab.name == 'b') {
          this.getZdhDetail()
        }

        // this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.showEdit()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.showEdit()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.showEdit()
      },

      async getZdhDetail() {
        this.listLoading = true
        memberDetail(this.id).then(({ data }) => {
          this.form = data
          var order_arr1 = []

          data.kor_order.forEach((item, index) => {
            item.order_brand.forEach((t, i) => {
              var obj1 = {
                express_code: item.express_code,
                one_code: item.code,
                weight: item.weight,
                price: item.price,
                pay_time: item.pay_time,
              }
              let object = { ...t, ...obj1 }
              order_arr1.push(object)
            })
          })
          this.listLoading = false

          this.list = order_arr1
          this.total = data.count
          this.imageUrl = data.pic
          this.srcList.push(data.pic)

          this.dialogFormVisible = true
          // this.listLoading=false
        })

        // const { data } = await memberDetail(row.id)
      },

      async getHdzDetail(row) {
        if (row) {
          this.queryForm.id = row.id
        }
        this.listLoading = true
        memberDetail(this.queryForm).then(({ data }) => {
          this.form = data
          var order_arr = []
          data.order.forEach((item, index) => {
            item.order_brand.forEach((t, i) => {
              var obj = {
                express_code: item.express_code,
                one_code: item.code,
                weight: item.weight,
                price: item.price,
                pay_time: item.pay_time,
              }
              let object = { ...t, ...obj }
              order_arr.push(object)
            })
          })
          this.list = order_arr
          this.total = data.count
          this.imageUrl = data.pic
          this.srcList.push(data.pic)
          // this.form = Object.assign({}, row)
          this.dialogFormVisible = true
          this.listLoading = false
        })
      },
      goBack() {
        // this.$router.go(-1)
        this.close()
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        ;(this.activeName = 'a'), (this.dialogFormVisible = false)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await memberUdate(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }

  :deep .el-dialog__body {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
