import request from '@/utils/request'
//获取邀请码集合
export function getInvitationList(data) {
  return request({
    url: 'admin/invitation/list',
    method: 'post',
    data: data,
  })
}
//添加邀请码
export function addList(data) {
  return request({
    url: 'admin/invitation/add',
    method: 'post',
    data: data,
  })
}
//通过ID 删除邀请码
export function deleteList(data) {
  return request({
    url: 'admin/invitation/del',
    method: 'post',
    data: data,
  })
}
//修改邀请码
export function updateInvitation(data) {
  return request({
    url: 'admin/invitation/update',
    method: 'post',
    data: data,
  })
}
//通过ID 获取邀请码的详情
export function InvitationDetail(data) {
  return request({
    url: 'admin/invitation/detail',
    method: 'post',
    data: data,
  })
}
