<template>
  <div class="table-container">
    <vab-query-form>
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item label="运送类型">
          <el-select
            v-model="queryForm.shipment_type"
            clearable
            placeholder="请选择"
            @keyup.enter.native="handleQuery"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        width="120"
        align="center"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <!-- 名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="type"
        label="运送类型"
        align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="row.shipment_type == 1">jc海运</div>
          <div v-if="row.shipment_type == 2">jc空运</div>
          <div v-if="row.shipment_type == 3">EMS海运</div>
          <div v-if="row.shipment_type == 4">EMS空运</div>
        </template>
      </el-table-column>

      <!-- 首重 -->
      <el-table-column
        show-overflow-tooltip
        prop="first_number"
        label="首重"
        align="center"
      ></el-table-column>

      <!-- 首重价格 -->
      <el-table-column
        show-overflow-tooltip
        prop="first_amount"
        label="首重价格"
        align="center"
      ></el-table-column>

      <!-- 续重 -->
      <el-table-column
        show-overflow-tooltip
        prop="repeat_number"
        label="续重"
        align="center"
      ></el-table-column>

      <!-- 续重价格 -->
      <el-table-column
        show-overflow-tooltip
        prop="repeat_amount"
        label="续重价格"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="250"
        align="center"
        sortable
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="150px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <!-- <el-button type="text" style="color: #ff0000" @click="handleDelete(row)">
                        删除
                    </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <kor-postage ref="edit" @fetch-data="getnewlist"></kor-postage>
  </div>
</template>

<script>
  import { getKopPostageList } from '@/api/korPostage'
  import korPostage from '../components/korPostage'

  export default {
    name: 'ComprehensiveTable',
    components: {
      korPostage,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        form: {},
        imgShow: true,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        options: [
          {
            value: 1,
            label: 'CJ海运',
          },
          {
            value: 2,
            label: 'CJ空运',
          },
          {
            value: 3,
            label: 'EMS海运',
          },
          {
            value: 4,
            label: 'EMS空运',
          },
        ],
        height: '73vh',
      }
    },
    computed: {},
    created() {
      this.fetchData()
    },

    beforeDestroy() {},
    mounted() {},
    methods: {
      //初始化方法
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },

      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },

      async fetchData() {
        this.listLoading = true
        const { data } = await getKopPostageList(this.queryForm)
        this.list = data.list
        this.total = data.count

        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
