<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="800px"
    @close="close"
  >
    <el-form ref="form" class="form-x" :model="form" :rules="rules">
      <!-- 标题 -->
      <el-form-item label="标题" prop="title">
        <el-input
          v-model.trim="form.title"
          autocomplete="off"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>
      <!-- 描述 -->
      <el-form-item label="描述" prop="remark">
        <el-input
          v-model.trim="form.remark"
          autocomplete="off"
          placeholder="请输入描述"
        ></el-input>
      </el-form-item>

      <!-- 价格 -->
      <el-form-item label="价格" prop="money">
        <el-input
          v-model.trim="form.money"
          autocomplete="off"
          placeholder="请输入价格"
        ></el-input>
      </el-form-item>

      <el-form-item label="有效期类型">
        <el-radio-group v-model="radio">
          <el-radio :label="1">永久有效</el-radio>
          <el-radio :label="2">固定时间段</el-radio>
          <el-radio :label="3">领取后多少天有效</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 天数 -->

      <el-form-item v-if="radio == 3" label="天数" prop="days">
        <el-input
          v-model.trim="form.days"
          autocomplete="off"
          placeholder="请输入天数"
          size="small"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="radio == 2" label="时间段选择">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>

      <!-- 最小使用金额(0为不限制) -->
      <el-form-item
        v-if="radio == 1"
        label="最小使用金额(0为不限制) "
        prop="at_least"
      >
        <el-input
          v-model.trim="form.at_least"
          autocomplete="off"
          placeholder="请输入最小使用金额"
        ></el-input>
      </el-form-item>

      <!-- 发放数量 -->
      <el-form-item label="发放数量 " prop="count">
        <el-input
          v-model.trim="form.count"
          autocomplete="off"
          placeholder="请输入发放数量"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { couponAdd, couponUpdate, couponDel } from '@/api/xgcApi'

  export default {
    name: 'ManageStore',
    data() {
      return {
        form: {},
        rules: {
          title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
          remark: [{ required: true, trigger: 'blur', message: '请输入描述' }],
          money: [{ required: true, trigger: 'blur', message: '请输入金额' }],
        },
        title: '',
        dialogFormVisible: false,
        radio: 1,
        value1: '',
        isShow: true,
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '添加优惠券'
          this.isShow = false
        } else {
          this.title = '编辑'
          this.isShow = true
          const { data } = await brandDetail(row.id)
          this.form = data



        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },

      time() {
        this.form.start_time = this.value1[0]
        this.form.end_time = this.value1[1]
      },
      save() {
        this.time()


        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == false) {
              const { msg } = await couponAdd(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              const { msg } = await brandUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style>
  /*  .form-x{

     .el-form-item__label{
      float: none;
      word-break: break-word;
    } */
</style>
