import request from '@/utils/request'
//获取轮播图list
export function getBannerList(data) {
  return request({
    url: 'admin/banner/list',
    method: 'post',
    data: data,
  })
}
//删除轮播图
export function deleteList(data) {
  return request({
    url: 'admin/banner/del',
    method: 'post',
    data: data,
  })
}
//添加轮播图
export function addList(data) {
  return request({
    url: 'admin/banner/add',
    method: 'post',
    data: data,
  })
}
//修改轮播图
export function updateBanner(data) {
  return request({
    url: 'admin/banner/update',
    method: 'post',
    data: data,
  })
}
//通过id查询轮播图详情
export function bannerDetail(data) {
  return request({
    url: 'admin/banner/detail',
    method: 'post',
    data: data,
  })
}
