import request from '@/utils/request'
//获取模版list
export function getKopPostageList(data) {
  return request({
    url: '/kor_admin/postage/list',
    method: 'post',
    data: data,
  })
}

//添加模版
export function addList(data) {
  return request({
    url: '/kor_admin/postage/add',
    method: 'post',
    data: data,
  })
}

//通过id查询模版详情
export function getKopPostageDetail(data) {
  return request({
    url: '/kor_admin/postage/detail',
    method: 'post',
    data: data,
  })
}

//修改模版
export function updateKopPostage(data) {
  return request({
    url: 'kor_admin/postage/update',
    method: 'post',
    data: data,
  })
}
