<template>
  <div class="app-container">
    <h4 class="form-header h4">编辑配送邮费模版</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="140px" style="padding-left: 24px">
      <el-form-item label="邮费模版名称" prop="name">
        <el-input v-model="form.name" :disabled="true" placeholder="请输入名称" style="width: 240px" />
      </el-form-item>

      <el-form-item label="国家" prop="type">
        <template>
          <el-radio v-model="form.type" disabled :label="1">韩国</el-radio>
          <el-radio v-model="form.type" disabled :label="2">日本</el-radio>
        </template>
      </el-form-item>

      <!-- 选择省份 -->
      <el-form-item label="省份">
        <template>
          <el-select v-model="form.city" style="width: 240px" multiple placeholder="请选择">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </template>
      </el-form-item>
      <!-- 快递方式 -->
      <el-form-item label="快递方式" prop="express_type">
        <el-radio v-model="form.express_type"  :label="1">圆通快递</el-radio>
        <el-radio v-model="form.express_type"  :label="3">顺丰快递</el-radio>
      </el-form-item>
      <el-form-item label="YKF首重(kg)" prop="first_number">
        <el-input v-model="form.first_number" placeholder="请输入首重" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="YKF首重价格(元)" prop="first_amount">
        <el-input v-model="form.first_amount" placeholder="请输入首重价格" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="YKF续重(kg)" prop="repeat_number">
        <el-input v-model="form.repeat_number" placeholder="请输入续重" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="YKF续重价格(元)" prop="repeat_amount">
        <el-input v-model="form.repeat_amount" placeholder="请输入续重价格" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="HJ首重(kg)" prop="kfh_first_number">
        <el-input v-model="form.kfh_first_number" placeholder="请输入HJ首重" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="HJ首重价格(元)" prop="kfh_first_amount">
        <el-input v-model="form.kfh_first_amount" placeholder="请输入HJ首重价格" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="HJ续重(kg)" prop="kfh_repeat_number">
        <el-input v-model="form.kfh_repeat_number" placeholder="请输入HJ续重" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item label="HJ续重价格(元)" prop="kfh_repeat_amount">
        <el-input v-model="form.kfh_repeat_amount" placeholder="请输入HJ续重价格" style="width: 240px" @input="handleInput" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
        <el-button @click="close()">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import {
    getCityList,
    postageDetail,
    updatePostage
  } from '@/api/postage'
  export default {
    data() {
      return {
        //省份集合
        cityList: [],
        cityForm: {},

        id: '',
        form: {
          city: [], //省份的集合
          type: '', //类型 1韩国 2 日本
          cityCode: '', //省份编码
          express_type:null
        },
        //用于编辑或者新增时的表单校验
        rules: {
          name: [{
            required: true,
            message: '邮费模版名称不能为空',
            trigger: 'blur',
          }, ],
          express_type: [{
            required: true,
            message: '请选择快递方式',
            trigger: 'change',
          }, ],
          city: [{
            required: true,
            message: '省份不能为空',
            trigger: 'blur'
          }],
          type: [{
            required: true,
            message: '类型不能为空',
            trigger: 'blur'
          }],
          first_number: [{
              required: true,
              message: '首重不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          first_amount: [{
              required: true,
              message: '首重价格不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^[0-9]+(.[0-9]{0,9})?$/,
              message: '价格只能为数字和小数',
              trigger: 'blur',
            },
          ],

          repeat_number: [{
              required: true,
              message: '续重不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          repeat_amount: [{
              required: true,
              message: '续重价格不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_first_number: [{
              required: true,
              message: 'khf首重不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_first_amount: [{
              required: true,
              message: 'khf首重价格不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_repeat_number: [{
              required: true,
              message: 'khf续重不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_repeat_amount: [{
              required: true,
              message: 'khf续重价格不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    created() {
      this.form.id = this.$route.query.id
      this.showEdit()
      this.list()
    },
    methods: {
      //输入价格和重量的时候 移除非数字和非小数点字符，保留小数点 保留第一个小数点，移除其他小数点 移除前导零，但保留小数点前的单个零。
      handleInput() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.first_number = this.form.first_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.first_amount = this.form.first_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.repeat_number = this.form.repeat_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.repeat_amount = this.form.repeat_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.kfh_first_number = this.form.kfh_first_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.kfh_first_amount = this.form.kfh_first_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.kfh_repeat_number = this.form.kfh_repeat_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')

        this.form.kfh_repeat_amount = this.form.kfh_repeat_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      //提交按钮
      async submitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            //把数组转化成字符串以逗号分隔 后台接受的是一个字符串。
            this.form.cityCode = this.form.city.join(',')
            console.log(this.form.express_type , '--------------')
            this.form.express_type1 = this.form.express_type
             updatePostage(this.form).then(msg=>{
              this.$baseMessage('修改成功', 'success')
              this.$refs['form'].resetFields()
              window.history.back();
            }).catch(err=>{
              console.log(err , '返回信息')
            })

          } else {
            return false
          }
        })
      },

      async list() {
        //获取城市集合
        const {
          data
        } = await getCityList(this.cityForm)
        this.cityList = data
      },
      async showEdit() {
        //获取邮费模板详情 用于编辑时候回显
        const {
          data
        } = await postageDetail(this.form)
        this.form = data
        this.form.type = data.type

        //需要把后台返回来的字符串转化成数组，因为多选的子组件的v-model 需要绑定一个数组。
        this.form.city = data.city.trim().split(',')
        this.form.city.forEach((item, index) => {
          this.form.city[index] = Number(item)
        })
      },
      /** 关闭按钮 */
      close() {
        //关闭以后需要跳转到首页index 并重新刷新页面
        this.$router.push({
          path: './managePostage'
        })
      },
    },
  }
</script>
<style scoped>
  .form-header {
    margin-top: 0;
    padding-left: 15px;
    height: 50px;
    box-sizing: border-box;
    background-color: rgba(128,
        128,
        128,
        0.1);
    /* 使用rgba设置背景颜色为灰色并设置透明度 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* 添加阴影效果，水平偏移为0，垂直偏移为2px，模糊半径为4px */
    line-height: 50px;
    font-size: 16px;
  }
</style>
