<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    top="3vh"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="100px">
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号" prop="code">
        <el-input
          v-model.trim="form.code"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
          :readonly="true"
        ></el-input>
      </el-form-item>
      <!-- 运单号 -->
      <el-form-item label="运单编号" prop="express_code">
        <el-input v-model.trim="form.express_code" :readonly="true"></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="订单价格" prop="price">
        <el-input v-model.trim="form.price" :readonly="true"></el-input>
      </el-form-item>
      <!-- 重量 -->
      <el-form-item label="订单重量" prop="weight">
        <el-input v-model.trim="form.weight" :readonly="true"></el-input>
      </el-form-item>
      <!--商品信息 -->
      <div class="xinxi" v-for="(item, index) in listInfo" :key="index">
        <el-form-item label="商品名称" prop="">
          <el-input v-model.trim="item.name"></el-input>
        </el-form-item>
        <el-form-item label="商品单价" prop="">
          <el-input v-model.trim="item.price"></el-input>
        </el-form-item>
        <el-form-item label="商品数量" prop="">
          <el-input v-model.trim="item.num"></el-input>
        </el-form-item>
      </div>

      <!-- 完善备注 -->
      <el-form-item label="备注信息" prop="remark">
        <el-input
          v-model.trim="form.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
          :readonly="true"
        ></el-input>
      </el-form-item>
      <!-- 用户名 -->
      <el-form-item label="收件人名" prop="name">
        <el-input v-model.trim="form.name" :readonly="true"></el-input>
      </el-form-item>
      <!-- 身份证号 -->
      <el-form-item label="身份证号" prop="idcode">
        <el-input v-model.trim="form.idcode" :readonly="true"></el-input>
      </el-form-item>
      <!-- 手机号 -->
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model.trim="form.phone" :readonly="true"></el-input>
      </el-form-item>

      <!-- 详细地址 -->
      <el-form-item label="详细地址" prop="dizhi">
        <el-input v-model.trim="form.dizhi" :readonly="true"></el-input>
      </el-form-item>
      <!-- 增值服务信息 -->

      <!-- 开箱方式 -->
      <el-form-item label="开箱方式" prop="shipping_type_name">
        <el-input
          v-model.trim="form.shipping_type_name"
          :readonly="true"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    orderDetailZdh,
    getBrandList,
    getBrandTypesList,
    orderBrandUpdateZdh,
  } from '@/api/xgcApi'

  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        dialogFormVisible: false,
        form: {},
        title: '订单详情',
        listInfo: [],
        brandList: [],
      }
    },

    created() {},
    methods: {
      // 获取详情数据
      async showEdit(row) {
        const { data } = await orderDetailZdh({ id: row.id })
        this.form = data
        this.list = data.brand
        this.listInfo = data.brand
        if (data.province == null) {
          this.form.dizhi = ''
        } else {
          this.form.dizhi = data.province + data.city + data.area + data.address
        }
        this.dialogFormVisible = true
      },

      // 点击关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 点击提交更改
      async save() {
        var date = {
          order_id: this.form.id,
          brand_list: this.listInfo,
        }
        orderBrandUpdateZdh(date).then(({ data, msg }) => {
          this.brandList = data
          this.$baseMessage(msg, 'success')
          this.$emit('getnewlist')
          this.close()
        })
      },
    },
  }
</script>
<style scoped>
  .black {
    font-weight: bold;
    color: #000000;
  }
  .xinxi {
    border: 1px solid #dcdfe6;
    margin-bottom: 5px;
    padding: 10px;
    box-sizing: border-box;
  }
  :deep .el-dialog__body {
    height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
