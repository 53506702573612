import request from '@/utils/request'
//获取申请代理list
export function getProxyList(data) {
  return request({
    url: 'kor_admin/Proxy/list',
    method: 'post',
    data: data,
  })
}

//通过id查询申请代理详情
export function ProxyDetail(data) {
  return request({
    url: 'kor_admin/Proxy/detail',
    method: 'post',
    data: data,
  })
}
