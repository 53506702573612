// 导出excel js
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { baseURL } from '@/config'
export default function export_files(URLs, data) {
  /*
   * @URLs: 请求地址路径
   * @data: 请求传参
   * @user: wqz
   * */
  let file = data.file
  let fileType = file.type
  var url = baseURL + URLs
  // console.log(url, '文件上传路径')
  // var url = '/jdApis/'+ URLs

  return new Promise(function (resolve, reject) {
    if (file) {
      // 创建Compressor实例
      let date = new FormData()
      date.append('file', file)
      axios({
        url: url,
        method: 'post',
        data: date,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          Message({
            message: err.message,
            type: 'error',
            duration: 1000,
          })
        })
    }
  })
}
