<template>
  <div class="navBer">
    <el-upload
      v-loading="loading"
      :http-request="upload"
      action="#"
      :limit="limit"
      :disabled="disable"
      :file-list="imgList"
      list-type="picture-card"
      :class="imgList.length >= limit ? 'hide' : 'show'"
      :before-upload="picChange"
    >
      <div slot="default" class="up_class">
        <i class="el-icon-upload"></i>
        添加
      </div>

      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disable"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70vh"

      :modal="false"
      :title="title"
    >
      <div v-if="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import exoprt_file from '@/utils/expor_file' // 导出excel文件
  import { getUploadUrl } from '@/config'
  // 单组件引用
  // import pdf from 'vue-pdf'
  // import pdfjsLib from 'pdfjs-dist/build/pdf';

  export default {
    components: {},
    props: {
      limit: {
        type: Number,
        default: 1,
      },
      disable: {
        type: Boolean,
        default: false,
      },
      imgListString: {
        type: String,
        default: null,
      },
      mask: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        title: '图片详情',
        imgList: [],
        loading: false,
        dialogImageUrl: null,
        dialogVisible: false,
        upUrl: '/index/index/file', //上传图片的接口
      }
    },
    watch: {
      imgListString: {
        handler: function (val) {
          var list = []
          this.imgList = []
          if (val) {
            list = val.split(',')
            var arrs = []
            list.forEach((item, index) => {
              arrs.push({ url: item })
            })
            this.imgList = arrs
          } else {
            list = []
          }
        },
        immediate: true, //第一次刷新页面时就会执行
      },
    },
    mounted() {},
    methods: {
      picChange(file) {
        // 图片文件验证 png jpg jpeg
        // 普通的文件上传
        if (
          file.type == 'image/png' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/jpg'
        ) {
        } else {
          this.$message({
            type: 'error',
            message: '上传失败',
          })
          return false
        }
      },
      upload(file) {
        // 图片上传
        file = file.file
        var data = {
          file: file,
        }
        this.loading = true
        var url = ''
        exoprt_file(this.upUrl, data).then((res) => {
          var pic = getUploadUrl + res
          console.log(pic, '89898989')
          this.imgList = [...this.imgList, { url: getUploadUrl + res }]
          this.loading = false
          this.emitChange()
        })
      },
      handleRemove(file) {
        // 删除图片
        // this.imgList = []
        var url = file.url
        this.imgList.forEach((item, index) => {
          if (url == item.url) {
            delete this.imgList.splice(index, 1)
          }
        })
        this.emitChange()
      },
      emitChange() {
        // 调用这个方法 返回给父组件
        var arr = []
        this.imgList.forEach((item, index) => {
          arr.push(item.url)
        })
        this.$emit('fileChange', arr)
      },
      handlePictureCardPreview(file) {
        // 查看详情
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
    },
  }
</script>
<style>
  .el-upload-list__item-thumbnail {
    transition: none !important;
  }
  .murked canvas {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
</style>
<style scoped lang="scss">
  /* 移除删除文件时的动画 */
  .pdf {
    position: relative;
  }
  .pdf_meng {
    position: absolute;
    top: 200px;
    left: 200px;
    width: 150px;
    height: 30px;
    z-index: 100;
    background-color: #ccc;
  }

  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  ::v-deep .el-upload-list__item.is-ready {
    //因为此饿了么组件图片上传的时候会产生一个类,将其隐藏就可以解决上传图片完成后闪动的问题
    display: none;
  }
  .show {
    display: flex;
    flex-wrap: wrap;
  }
  .navBer::v-deep .el-upload-list--picture-card {
    margin: 0;
    display: block;
    /* vertical-align: top; */
    display: contents;
  }

  .navBer::v-deep .el-upload--picture-card {
    width: 200px;
    height: 124px;
    margin-top: 15px;
    border-radius: 8px;
  }
  .navBer::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 200px;
    height: 124px;
    border-radius: 8px;
    margin: 15px 8px 0px 0px;
    border: 1px solid #c0ccda;
  }

  .up_class {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 200px;
    height: 134px;
    line-height: 20px;
  }
  .hide {
    position: relative;
    float: left;
    ::v-deep {
      .el-upload--picture-card {
        display: none;
      }

      .el-upload-list__item {
        border: 0;
        border-radius: 0;
        margin: 0 15px 0 0;
      }
    }
  }
</style>
