<template>
  <div class="userManagement-container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      style="display: flex"
    >
      <el-form-item label="用户ID" prop="code" style="width: 250px">
        <el-input
          v-model.trim="form.code"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 250px" label="用户名" prop="nickname">
        <el-input
          v-model.trim="form.nickname"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item style="width: 250px" label="电话号" prop="mobile">
        <el-input
          v-model.trim="form.mobile"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item style="width: 250px" label="添加时间" prop="add_time">
        <el-input
          v-model.trim="form.add_time"
          :disabled="true"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="头像" prop="pic">
          <el-image
            style="width: 100px; height: 100px"
            :src="imageUrl"
            :preview-src-list="srcList">
          </el-image>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="goBack()">返回</el-button>
      <!-- <el-button type="primary" @click="save">确 定</el-button> -->
    </div>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 多选 -->
      <el-table-column show-overflow-tooltip type="selection"></el-table-column>
      <!-- 订单编号 -->
      <el-table-column
        show-overflow-tooltip
        prop="one_code"
        label="one_code"
      ></el-table-column>

      <!--运单号 -->
      <el-table-column
        show-overflow-tooltip
        prop="express_code"
        label="运单号"
      ></el-table-column>
      <!-- 品牌名 -->
      <el-table-column
        show-overflow-tooltip
        prop="brand_name"
        label="品牌名"
      ></el-table-column>

      <!--品牌类型 -->
      <el-table-column
        show-overflow-tooltip
        prop="brand_type_name"
        label="品牌类型"
      ></el-table-column>
      <!--品牌价格 -->
      <el-table-column
        show-overflow-tooltip
        prop="price"
        label="品牌价格"
      ></el-table-column>
      <!--数量 -->
      <el-table-column
        show-overflow-tooltip
        prop="num"
        label="数量"
      ></el-table-column>
      <!--重量 -->
      <el-table-column
        show-overflow-tooltip
        prop="weight"
        label="重量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="pay_time"
        label="支付时间"
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { memberUdate, memberDetail } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'
  export default {
    data() {
      return {
        id: '',
        form: {},
        dialogFormVisible: false,
        rules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          password: [
            { required: true, trigger: 'blur', message: '请输入密码' },
          ],
          email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
          permissions: [
            { required: true, trigger: 'blur', message: '请选择权限' },
          ],
        },
        title: '',
        imageUrl: '',
        actionUrl: getUploadUrl + '/index/index/file',
        queryForm: {},
        list: null,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        srcList:[]
      }
    },
    created() {
      this.id = this.$route.query.id
      this.showEdit()
    },
    methods: {
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.showEdit()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.showEdit()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.showEdit()
      },
      async showEdit() {
        const { data } = await memberDetail(this.id)
        this.form = data

        var order_arr = []
        data.order.forEach((item, index) => {
          item.order_brand.forEach((t, i) => {
            var obj = {
              express_code: item.express_code,
              one_code: item.code,
              weight: item.weight,
              price: item.price,
              pay_time: item.pay_time,
            }
            let object = { ...t, ...obj }
            order_arr.push(object)
          })
        })
        this.list = order_arr

        this.total = data.count
        this.imageUrl = data.pic
        this.srcList.push(data.pic)
        // this.form = Object.assign({}, row)
        this.dialogFormVisible = true
      },
      goBack() {
        this.$router.go(-1)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await memberUdate(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style></style>
