<template>
  <el-dialog
    :title="title"
    top="8vh"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form
      v-if="dialogFormVisible"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="所属用户" prop="uid">
        <el-select
          v-model="form.uid"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="`${item.nickname} （${item.code ? item.code : '-'}）`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="寄往仓库" prop="type">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="isShow == false ? true : false"
        label="物品链接"
        prop="link"
      >
        <el-input
          v-model.trim="form.link"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
          placeholder="请输入物品链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="购买平台" prop="platform">
        <el-input
          v-model.trim="form.platform"
          placeholder="请输入购买平台"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="isShow" label="平台账号" prop="account">
        <el-input
          v-model.trim="form.account"
          placeholder="请输入平台账号"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="isShow" label="账号密码" prop="password">
        <el-input
          v-model.trim="form.password"
          placeholder="请输入账号密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="物品名称" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入物品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="物品款式" prop="style">
        <el-input
          v-model.trim="form.style"
          placeholder="请输入物品款式"
        ></el-input>
      </el-form-item>

      <el-form-item label="物品数量" prop="num">
        <el-input
          v-model.trim="form.num"
          placeholder="请输入物品数量"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="订单备注" prop="remark">
        <el-input
          v-model.trim="form.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
        ></el-input>
      </el-form-item>

      <!-- 产品图片 -->
      <el-form-item label="物品图片" prop="pic">
        <uploadFile
          :limit="10"
          :img-list-string="form.pic"
          @fileChange="uploadChange($event, 'pic')"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { buyListAdd, memberListAll } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'
  import uploadFile from '@/components/uploadFile'
  import { goodsAdd } from '../../../api/xgcApi'
  export default {
    name: 'UserManagementEdit',
    components: {
      uploadFile,
    },
    data() {
      return {
        loading: false,
        options: [],
        typeList: [
          { label: '韩国仓库', value: 1 },
          { label: '日本仓库', value: 2 },
        ],
        form: {},
        dialogFormVisible: false,
        rules: {
          type: [
            { required: true, trigger: 'blur', message: '请选择寄往仓库' },
          ],
          platform: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],
          account: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],
          password: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],
          num: [{ required: true, trigger: 'blur', message: '请输入包裹单号' }],
          name: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],
          style: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],

          uid: [
            { required: true, trigger: 'change', message: '请选择所属用户' },
          ],
        },
        title: '',
        isShow: false,
      }
    },
    methods: {
      // 用户筛选
      remoteMethod(e) {
        this.page = 1
        this.limit = 20
        var data = {
          nickname: e,
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({ data }) => {
          this.options = data
        })
      },
      // 输入校验
      handleInputWeight() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.weight = this.form.weight
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      handleInputPrice() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.weight = this.form.weight
        this.form.price = this.form.price
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },

      // 上传图片
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      // 初始化请求数据
      async showEdit(row) {
        this.dialogFormVisible = true
        this.getUserInfo()
        if (row.type2 == 1) {
          this.form.type2 = row.type2
          this.title = '申请代买'
          this.isShow = false
        }
        if (row.type2 == 2) {
          this.title = '申请代付'
          this.isShow = true
          this.form.type2 = row.type2
        }
      },
      // 获取用户全量昵称，code
      async getUserInfo() {
        var data = {
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({ data }) => {
          if (this.form.userInfo) {
            var a = JSON.stringify(data).indexOf(this.form.userInfo.code)
            if (a < 0) {
              data.push(this.form.userInfo)
            }
          }
          this.options = data
          this.loading = false
        })
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        for (var key in this.form) {
          this.form[key] = null
        }
        this.form.value = null
        this.dialogFormVisible = false
      },
      // 提交修改//修改传ID  添加不传ID
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { data, msg } = await buyListAdd(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  :deep .el-input--small .el-input__inner {
    width: 417px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  :deep .el-dialog__body {
    height: 66vh;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
