<template>
  <div class="table-container">
     <el-button @click="addBtn" type="primary" style="margin-bottom: 15px;">添加平台</el-button>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
    >
      <el-table-column align="center" prop="id" min-width="90" show-overflow-tooltip label="ID"></el-table-column>
      <el-table-column align="center" prop="name" label="平台名称" min-width="180"></el-table-column>
      <el-table-column align="center" prop="link" label="平台链接" min-width="180"></el-table-column>
      <el-table-column align="center" prop="link" label="平台图片" min-width="180">
        <template #default="{ row }">
            <img style="width: 100px; height: auto;" :src="row.pic" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="update_tim  e" label="修改时间" min-width="180"></el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        show-overflow-tooltip
        label="操作面板"
        width="180px"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-popconfirm
            confirm-button-text='确认'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="确认删除此条？"
            @confirm="del(row)"
          >
            <el-button slot="reference" type="text" style="color: red;margin-left: 5px;">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <edits @submit="submitOk" :add="isAdd" @close="(e)=>{pop_show = !pop_show}" :form="item" :isSHow="pop_show" />
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>

  </div>
</template>

<script>
  import { Link_list , Link_del} from '@/api/xgcApi'
  import edits from './components/edit'

  export default {
    name: 'edit',
    components: {
      edits,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        isAdd:false,
        item:{},
        list: [],
        listLoading: true,
        height:'75vh',
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        pop_show:false,
        queryForm: {
          page: 1,
          limit: 20,
        },
      }
    },
    beforeDestroy() {},
    mounted() {
      this.getList()
    },
    methods: {
      del(row){
        console.log(row)
          Link_del({id:row.id}).then(res=>{
            this.$message({
              type:'success',
              message:'操作成功'
            })
            this.getList()
          })
      },
      submitOk(){
        this.getList()
        this.pop_show = false
      },
      getList(){
        let data = {
          "page": this.queryForm.page, //页码
          "limit": this.queryForm.limit //条数
        }
        Link_list(data).then(res=>{
          this.list = res.data.list
          this.total = res.data.count
          this.listLoading = false
        }).catch(err=>{
          this.listLoading = false
        })
      },
      handleCurrentChange(e){
        console.log(e , '分页')
      },
      handleSizeChange(e){
        console.log(e , 'size')
      },
      handleEdit(row){
        this.isAdd = false
        this.pop_show = true
        this.item = row
      },
      addBtn(){
        this.item = {

        }
        this.isAdd = true
        this.pop_show = true
      }
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
