<template>
  <div class="table-container">
    <!-- <vab-query-form>
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加账号
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form> -->

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        width="120"
        align="center"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <!-- 名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="250"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="150px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <!-- <el-button type="text" @click="handleDel(row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <!-- 添加修改管理员对话框 -->
    <el-dialog
      :title="IsAdd?'添加系统用户':'修改用户详情'"
      :visible.sync="dialogVisible"
      width="50%"
      :destroy-on-close="true"
      :before-close="handleClose">
      <div>
        <el-form
          ref="form"
           :rules="rules"
          :model="addForm"
          label-width="100px"
        >
          <el-form-item label="账号" prop="name">
              <el-input v-model="addForm.name" maxlength="20" type="text" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="用户组" prop="gid">
               <el-select v-model="addForm.gid" placeholder="请选择活动区域">
                    <el-option v-for="(item , index) in gidList" :key="index" :label="item.name" :value="item.id"></el-option>
               </el-select>
          </el-form-item>
          <el-form-item v-if="IsAdd" label="密码" prop="password">
              <el-input v-model="addForm.password" maxlength="20" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item v-if="!IsAdd" label="密码" >
              <el-input v-model="addForm.password" maxlength="20" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item v-if="addForm.password" label="确认密码"  prop="shurePassword">
              <el-input v-model="addForm.shurePassword" maxlength="20" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button  @click="dialogVisible=false" type="primary" >
              取消
            </el-button>
            <el-button  @click="addSubmit" type="primary" >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    user_list,
    user_add,
    AdminGroup_list,
    user_detail,
    user_update,
    user_del
  } from '@/api/xgcApi'

  export default {
    name: 'ComprehensiveTable',
    components: {
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        gidList:[],//用户组列表
        rules:{
          name: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
          shurePassword: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            {required: true, validator: (rule, value, callback)=>{
              if(this.addForm.password == value){
                callback()
              }else{
                callback(new Error('两次输入密码不一致!'));
              }
            }, trigger: 'blur' }
          ],
        },//添加修改管理员表单验证
        addForm:{},//管理员信息
        IsAdd:true,//是否是添加 true添加 false修改
        dialogVisible:false,//添加管理员对话框
        form: {},
        imgShow: true,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        height: '73vh',
      }
    },
    computed: {},
    created() {

      this.getGroupList()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      handleDel(row){
         this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            user_del({id:row.id}).then(res=>{
              this.$message({
                type:'success',
                message:'操作成功'
              })
              this.fetchData()
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });

      },
      getGroupList(){
        // 获取用户组列表
        var data = {
          "page": 1, //页码
          "limit": 50 //条数
        }
        AdminGroup_list(data).then(res=>{
          console.log(res.data)
          this.gidList = res.data.list
          this.fetchData()
        })
      },
      addSubmit(){
        // 添加账号
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var data = {
              name:this.addForm.name,
              password:this.addForm.password,
              gid:this.addForm.gid,
            }
            if(this.IsAdd){
              user_add(data).then(res=>{
                  this.$message({
                    type:'success',
                    message:'操作成功'
                  })
                  this.fetchData()
                  this.dialogVisible = false
              })
            }else{
              data.id = this.addForm.id
              user_update(data).then(res=>{
                  this.$message({
                    type:'success',
                    message:'操作成功'
                  })
                  this.fetchData()
                  this.dialogVisible = false
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 关闭添加修改对话框
      handleClose(){
        this.dialogVisible = false
      },
      //初始化方法
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.addForm = {}
        this.IsAdd = true
        this.dialogVisible = true
      },
      handleEdit(row) {
        this.IsAdd = false
        this.listLoading = true
        var data = {
          id:row.id
        }
        user_detail(data).then(res=>{
          this.listLoading = false
          this.dialogVisible = true
          this.addForm = res.data
          this.addForm.password = ''
        }).catch(err=>{
          this.listLoading = false
        })

      },

      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },

       fetchData() {
        this.listLoading = true
        user_list(this.queryForm).then(res=>{

          this.list = res.data.list
          this.total = res.data.count
        })
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
