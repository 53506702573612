<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 编码 -->
      <el-form-item label="品牌名" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!-- 邮编 -->
      <el-form-item label="序号" prop="sort">
        <el-input
          v-model.trim="form.sort"
          autocomplete="off"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="价格" prop="price">
        <el-input
          v-model.trim="form.price"
          autocomplete="off"
          placeholder="请输入"
        ></el-input>
      </el-form-item>

      <!-- 邮编 -->
      <el-form-item v-if="isShow" label="创建时间" prop="add_time">
        <el-input
          v-model.trim="form.add_time"
          autocomplete="off"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { serviceDetail, serviceUpdate, serviceAdd } from '@/api/xgcApi'

  export default {
    name: 'ManageStore',
    data() {
      return {
        form: {},
        rules: {
          title: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          author: [{ required: true, trigger: 'blur', message: '请输入内容' }],
        },
        title: '',
        dialogFormVisible: false,
        isShow: true,
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.isShow = true
          this.title = '编辑'
          const { data } = await serviceDetail(row.id)
          this.form = data
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == false) {
              const { msg } = await serviceAdd(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              const { msg } = await serviceUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
