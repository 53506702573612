<template>
  <el-form
    ref="form"
    :model="form"
    label-width="100px"
    :rules="rules"
    style="padding: 15px"
  >
    <el-form-item label="快递单号" prop="code">
      <el-input
        v-model="form.code"
        style="width: 215px"
        placeholder="请输入快递单号"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="仓库所在地" prop="value" style="margin-bottom: 0">
      <el-select v-model="form.value" clearable placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <span
      v-if="showCangku"
      style="margin-left: 100px; color: #ff0000; font-size: 12px"
    >
      请选择仓库所在地
    </span>
    <el-form-item label="">
      <el-button
        icon="el-icon-plus"
        type="primary"
        @click="addItem"
        style="margin-top: 18px"
      >
        添加
      </el-button>
    </el-form-item>
    <div v-for="(item, index) in form.list" :key="index">
      <el-form-item label="中文品名">
        <el-input v-model="item.name" style="width: 215px"></el-input>
        <span v-if="item.isOk" style="margin-left: 10px; color: #ff0000">
          必填项
        </span>
      </el-form-item>
      <el-form-item label="单价">
        <el-input v-model="item.price" style="width: 215px"></el-input>
        <span v-if="item.isOk" style="margin-left: 10px; color: #ff0000">
          必填项
        </span>
      </el-form-item>
      <el-form-item label="数量">
        <el-input v-model="item.num" style="width: 215px"></el-input>
        <span v-if="item.isOk" style="margin-left: 10px; color: #ff0000">
          必填项
        </span>
      </el-form-item>

      <el-form-item>
        <el-button
          v-if="show"
          icon="el-icon-delete"
          type="danger"
          @click="delItem(index)"
        >
          删除
        </el-button>
      </el-form-item>
    </div>
    <el-form-item label="备注" prop="remark">
      <el-input
        v-model.trim="form.remark"
        style="width: 215px"
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
      <el-button @click="close()">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { buyOrderAdd } from '@/api/xgcApi'
  export default {
    data() {
      return {
        showCangku: false,
        rules: {
          code: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          value: [{ required: true, trigger: 'blur', message: '请选择仓库' }],
        },

        form: {
          code: '',
          value: '',
          list: [
            {
              name: '',
              price: '',
              num: '',
              isOk: false,
            },
          ],
        },
        options: [
          {
            value: '1',
            label: '韩国仓库',
          },
          {
            value: '2',
            label: '日本仓库',
          },
        ],

        id: '',
        show: false,
      }
    },

    // watch: {
    //   // 监听 selectedValue 的变化
    //   value(newValue) {
    //     this.handleSelection()
    //   },
    // },

    created() {
      this.id = this.$route.query.id
    },
    methods: {
      handleSelection() {
        if (this.value == '') {
          this.showCangku = true
        } else {
          this.showCangku = false
        }
      },

      show1() {
        if (this.form.list.length > 1) {
          this.show = true
        } else {
          this.show = false
        }
      },

      close() {
        this.$router.go(-1)
      },
      onSubmit() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            var date = {
              bid: this.id,
              code: this.form.code,
              type: this.form.value,
              item_info: this.form.list,
              remark: this.form.remark,
            }
            const { data, msg, code } = await buyOrderAdd(date)
            if (code == 200) {
              this.$baseMessage(msg, 'success')
              setTimeout(() => {
                this.close()
              }, 500)
            } else {
              this.$baseMessage(msg, '')
            }
          } else {
            return false
          }
        })
      },

      addItem() {
        var data = {
          name: '',
          price: '',
          num: '',
        }
        this.form.list.push(data)
        this.show1()
      },
      delItem(index) {
        this.form.list.splice(index, 1)
        this.show1()
      },
    },
  }
</script>

<style>
  .red {
    border: 1px solid red;
  }
</style>
