<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-left-panel>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="名称">
            <el-input
              v-model="queryForm.name"
              clearable
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>

      <vab-query-form-right-panel>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        width="120"
        align="center"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- 仓库名 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="名称"
        align="center"
      ></el-table-column>
      <!-- 价格 -->
      <el-table-column
        show-overflow-tooltip
        prop="price"
        label="价格"
        align="center"
      ></el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="300"
        sortable
        align="center"
      ></el-table-column>
      <!-- 显示状态 -->
      <el-table-column label="显示状态" align="center" width="250">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.is_delete"
            style="display: block"
            :inactive-value="1"
            :active-value="0"
            @change="handleSwitchChange(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- 操作面板 -->
      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="250"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <added-services ref="edit" @fetch-data="getnewlist"></added-services>
  </div>
</template>

<script>
  import { serviceList, serviceDel, serviceUpdate } from '@/api/xgcApi'
  import { upStatus } from '@/api/status'
  import addedServices from '../components/addedServices'
  export default {
    name: 'ComprehensiveTable',
    components: {
      addedServices,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        form: {},
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        height: '74vh',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    methods: {
      //修改，显示，隐藏，状态
      async handleSwitchChange(row) {
        this.form.id = row.id
        this.form.is_delete = row.is_delete
        const { msg } = await upStatus(this.form)
        this.$baseMessage(msg, 'success')
      },
      // 请求列表数据
      getnewlist() {
        this.fetchData()
      },
      // 添加方法
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      // 编辑方法
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询数据
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 增值服务列表数据
      async fetchData() {
        this.listLoading = true
        serviceList(this.queryForm).then((data) => {
          data = data.data
          this.listLoading = false
          this.list = data.list
          this.total = data.count
        })
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
  .el-switch {
    height: auto !important;
  }
</style>
