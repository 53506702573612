<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    top="10vh"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="100px">
      <!-- 用户ID -->
      <el-form-item label="用户ID" prop="user_code">
        <el-input v-model.trim="form.user_code" :readonly="true"></el-input>
      </el-form-item>
      <!-- 所属状态 -->
      <el-form-item label="订单状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 订单单号 -->
      <el-form-item label="订单单号" prop="code">
        <el-input v-model.trim="form.code" :readonly="true"></el-input>
      </el-form-item>
      <!-- 支付单号 -->
      <el-form-item label="支付流水号" prop="pay_code">
        <el-input v-model.trim="form.pay_code" :readonly="true"></el-input>
      </el-form-item>
      <!-- 退款单号 -->
      <el-form-item label="退款单号" prop="refund_code">
        <el-input v-model.trim="form.refund_code" :readonly="true"></el-input>
      </el-form-item>

      <!-- 退款备注 -->
      <el-form-item label="申请退款理由" prop="remark">
        <el-input v-model.trim="form.remark" :readonly="true"></el-input>
      </el-form-item>
      <!-- 退款备注 -->
      <el-form-item label="退款理由" prop="refund_remarks">
        <el-input
          v-model.trim="form.refund_remarks"
          :readonly="true"
        ></el-input>
      </el-form-item>
      <!-- 支付金额 -->
      <el-form-item label="支付金额" prop="price">
        <el-input v-model.trim="form.price" :readonly="true"></el-input>
      </el-form-item>
      <!-- 数量 -->
      <el-form-item label="订单数量" prop="num">
        <el-input v-model.trim="form.num" :readonly="true"></el-input>
      </el-form-item>
      <!--
      订单状态
      <el-form-item label="订单状态" prop="status">
        <el-input
          v-if="form.status == 0"
          v-model="weizhifu"
          :readonly="true"
        ></el-input>
        <el-input
          v-if="form.status == 1"
          v-model="yizhifu"
          :readonly="true"
        ></el-input>
        <el-input
          v-if="form.status == 2"
          v-model="tuikuanzhong"
          :readonly="true"
        ></el-input>
        <el-input
          v-if="form.status == 3"
          v-model="yituikuan"
          :readonly="true"
        ></el-input>
      </el-form-item> -->
      <!-- 支付时间 -->
      <el-form-item label="支付时间" prop="pay_time">
        <el-input v-model.trim="form.pay_time" :readonly="true"></el-input>
      </el-form-item>
      <!-- 申请退款时间 -->
      <el-form-item label="申请退款时间" prop="application_refund_time">
        <el-input
          v-model.trim="form.application_refund_time"
          :readonly="true"
        ></el-input>
      </el-form-item>
      <!-- 退款时间 -->
      <el-form-item label="退款时间" prop="refund_time">
        <el-input v-model.trim="form.refund_time" :readonly="true"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="save()">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    goodsOrderDetail,
    getBrandList,
    getBrandTypesList,
    orderBrandUpdate,
    goodsOrderStatus,
  } from '@/api/xgcApi'

  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        weizhifu: '待支付',
        yizhifu: '已支付',
        tuikuanzhong: '退款中',
        yituikuan: '已退款',
        dialogFormVisible: false,
        form: {},
        title: '订单详情',
        statusList: [
          {
            value: 0,
            label: '待支付',
          },
          {
            value: 1,
            label: '已支付',
          },
          {
            value: 4,
            label: '已发货',
          },
          {
            value: 2,
            label: '退款中',
          },
          {
            value: 3,
            label: '已退款',
          },
        ],
      }
    },

    methods: {
      // 获取详情数据
      async showEdit(row) {
        const { data } = await goodsOrderDetail({ id: row.id })
        this.form = data
        this.dialogFormVisible = true
      },
      // 点击关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 提交修改//修改传ID  添加不传ID
      save() {
        var setDate = {
          order_id: this.form.id,
          status: this.form.status || '',
        }
        goodsOrderStatus(setDate).then((res) => {
          this.$baseMessage(res.msg, 'success')
          this.close()
        })
      },
    },
  }
</script>
<style scoped>
  .black {
    font-weight: bold;
    color: #000000;
  }
  .red {
    color: #ff0000;
  }
  .xinxi {
    border: 1px solid #dcdfe6;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
</style>
