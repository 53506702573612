<template>
  <div>
    <div class="table-container">
      <vab-query-form>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input v-model="queryForm.code" placeholder="请输入订单编号" clearable />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form>

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        :element-loading-text="elementLoadingText"
        :height="height"
        border
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
        style="width: 100%"
      >
        <el-table-column
        align="center"
          show-overflow-tooltip
          type="selection"
          width="55"
        ></el-table-column>
        <!-- 商品图片 -->
        <el-table-column
         align="center" show-overflow-tooltip label="商品图片">
          <template #default="{ row }">
            <el-image
              v-if="imgShow"
              :preview-src-list="imageList"
              :src="row.pic"
            ></el-image>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column
        align="center"
          show-overflow-tooltip
          prop="code"
          label="订单编号"
          width="300"
        ></el-table-column>

        <!-- 包裹状态 -->
        <el-table-column
        align="center"
          show-overflow-tooltip
          prop="status_name"
          label="包裹状态"
        ></el-table-column>
        <!-- 订单金额 -->
        <el-table-column
        align="center"
          show-overflow-tooltip
          prop="price"
          label="订单金额"
        ></el-table-column>
        <!-- 订单重量 -->
        <el-table-column
        align="center"
          show-overflow-tooltip
          prop="weight"
          label="订单重量"
        ></el-table-column>
        <!-- 打印状态 -->
        <el-table-column
         align="center" show-overflow-tooltip prop="print" label="打印状态">
          <template #default="{ row }">
            <el-tag v-if="row.print == 0" size="mini" type="danger">
              未打印
            </el-tag>
            <el-tag v-if="row.print == 1" size="mini" type="success">
              已打印
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
        align="center"
          show-overflow-tooltip
          label="创建时间"
          prop="add_time"
          width="150"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="操作面板"
          width="180px"
          fixed="right"
          align="center"
        >
          <template #default="{ row }">
            <el-button size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-popconfirm
              confirm-button-text='删除'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              title="确定删除该包裹吗?"
              @confirm="delItem(row)"
            >
              <el-button style="margin-left: 5px;" slot="reference" type="danger" size="mini">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :background="background"
        :current-page="queryForm.page"
        :layout="layout"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <manage-parcel-tl ref="edit" @fetch-data="getnewlist"></manage-parcel-tl>
    </div>
  </div>
</template>

<script>
  import {
    orderList,
    goodsDel,
    orderStatus,
    orderNotice,
    oexcelOut,
    pullingCheck,
    Order_ClaimList,
    orderDel
  } from '@/api/xgcApi'
  import { Order_printStatus, Order_printCheck } from '@/api/print'
  import manageParcelTl from '../components/manageParcelTl'
  import parcelDetails from '../components/parcelDetails'
  import { baseURL_print, getUploadUrl } from '@/config'
  import { number } from 'echarts'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageParcelTl,
      parcelDetails,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height:'74vh',
        isShowFahuo: false,
        isShowQianshou: false,
        isShowDabao: false,
        pullFormBtn: false,
        doPrintLoading: false, //打印面单loading
        doPreviewLoading: false, //打印预览loading
        printTaskId: 0,
        printers: null, //打印相关
        chooseItem: [], //选中的数据
        defaultPrinter: null, //打印相关
        waybillNO: '0000000000000',
        waybillTemplateURL: baseURL_print + 'admin/index/print',
        imgShow: true,
        socket: null, //
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
          type: 0,
        },
        value1: '',
        value: '',
        activeName: 'a',
        remindCount: {},
        isShowCode: false,
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    methods: {
      // 删除待认领包裹
      delItem(row){
        var data = {
          id:row.id
        }
        console.log(data , '提交参数')
         orderDel(data).then(res=>{
           this.$message({
             type:'success',
             message:'删除成功'
           })
           this.fetchData()
         }).catch(err=>{
           this.$message({
             type:'error',
             message:'操作失败'
           })
         })
      },
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.status = ''
            this.pullFormBtn =
              this.isShowDabao =
              this.isShowFahuo =
              this.isShowQianshou =
                false
            break
          case 'b':
            this.queryForm.status = 0
            this.pullFormBtn = false
            this.isShowDabao = this.isShowQianshou = this.isShowFahuo = false
            break
          case 'c':
            this.queryForm.status = 1
            this.pullFormBtn = false
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'd':
            this.queryForm.status = 2
            this.pullFormBtn = this.isShowDabao = true
            this.isShowQianshou = this.isShowFahuo = false

            break
          case 'e':
            this.queryForm.status = 3
            this.pullFormBtn = this.isShowFahuo = true
            this.isShowDabao = this.isShowQianshou = false
            break
          case 'f':
            this.queryForm.status = 4
            this.pullFormBtn = true
            this.isShowDabao = this.isShowQianshou = this.isShowFahuo = false
            break
          case 'g':
            this.queryForm.status = 5
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'h':
            this.queryForm.status = 6
            this.pullFormBtn = this.isShowQianshou = true
            this.isShowDabao = this.isShowFahuo = false
            break
          case 'i':
            this.queryForm.status = 7
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'j':
            this.queryForm.status = 10
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
        }
        this.fetchData()
      },
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
        this.chooseItem = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit({ country: 1 })
      },
      // 点击修改编辑
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit1'].showEdit(row)
      },

      // 批量导出
      exportForm() {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定导出已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            const { data } = await oexcelOut({ id: ids })
            const fileUrl = getUploadUrl + data
            window.open(fileUrl)
            this.$message({ type: 'success', message: '导出成功' })
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },

      // 批量打包
      setQuery(e) {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定打包已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            const { msg } = await orderStatus({ order_id: ids, status: e })
            this.$message({ type: 'success', message: '打包成功' })
            this.fetchData()
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },

      pullForm(e) {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定要拉取选中项吗', null, async () => {
            const { data, code } = await pullingCheck({ id: ids, type: e })
            if (code == 200) {
              const { msg } = await orderNotice({ id: ids, type: e })

              this.$baseMessage(msg, 'success')
            } else {
              this.$baseMessage(msg, 'error')
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        Order_ClaimList(this.queryForm).then(({data})=>{
          this.list = data.list
          this.remindCount = data.statusCount
          this.total = data.count
          const imageList = []
          data.list.forEach((item, index) => {
            imageList.push(item.pic)
          })
          this.imageList = imageList
          this.listLoading = false
        })
      },

      /**
       * @name wqz
       * @function 面单打印相关方法
       * @time 2024年4月18日09:32:42
       */
      doConnect() {
        var that = this
        this.socket = new WebSocket('ws://127.0.0.1:13528')
        var result = '打印成功'
        var tmptaskID = '0'
        var that = this
        // 打开Socket
        that.socket.onopen = function (event) {
          // 监听消息
          that.socket.onmessage = function (event) {
            var data = JSON.parse(event.data)
            that.printers = data.printers
            that.defaultPrinter = data.defaultPrinter
            if (data.cmd == 'notifyPrintResult') {
              // 打印出现问题
              var result = '打印成功'
              if (data.taskID != tmptaskID) {
                for (var i = 0; i < data.printStatus.length; i++) {
                  if (data.printStatus[i].status == 'failed') {
                    result = '打印失败'
                    that.$message({ type: 'error', message: '打印失败' })
                    break
                  } else if (data.printStatus[i].status == 'canceled') {
                    that.$message({ type: 'error', message: '取消打印任务' })
                    result = '取消打印任务'
                    break
                  }
                }
                tmptaskID = data.taskID
              }
              that.doPreviewLoading = false
              that.doPrintLoading = false
            } else if (data.cmd == 'print') {
              // 这里面写打印/预览成功之后的回调
              that.doPreviewLoading = false
              that.doPrintLoading = false
              if (data.previewURL) {
                // 预览成功打开pdf文件url
                window.open(data.previewURL)
              } else {
                // 打印成功
                // var date =this.chooseItem
                var ids = []
                that.chooseItem.forEach((item, index) => {
                  ids.push(item.id)
                })
                var date = {
                  id: ids.join(','),
                }
                if (data.status != 'success') {
                  that.$message({ type: 'error', message: data.msg })
                } else {
                  Order_printStatus(date).then((res) => {
                    // 这个是打印成功回调
                    that.$message({ type: 'success', message: '打印成功' })
                  })
                }
              }
            }
          }
          // 监听Socket的关闭
          that.socket.onclose = function (event) {
            that.$message({
              type: 'error',
              message: '打印机失去联系，请刷新网页重新连接',
            })
          }
        }
        that.socket.onerror = function (event) {
          that.$message({ type: 'error', message: '打印机出现问题,请联系客服' })
        }
      },

      // 打印预览
      doPreview() {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join(',')
          this.$baseConfirm('你确定打印预览选中项吗', null, async () => {
            var data = {
              id: ids,
            }
            Order_printCheck(data).then((res) => {
              var printData = this.getPrintData()
              printData.task.preview = true
              this.doPreviewLoading = true
              this.socket.send(JSON.stringify(printData))
            })
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      //打印电子面单
      doPrint() {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定打印选中项吗', null, async () => {
            var data = {
              id: ids,
            }
            Order_printCheck(data).then((res) => {
              var printData = this.getPrintData()
              this.doPrintLoading = true
              this.socket.send(JSON.stringify(printData))
            })
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 组装打印机需要的参数
      getPrintData() {
        var dataArr = []
        this.chooseItem.forEach((item, ind) => {
          // if (item.id == 1727) {
          //   // 模拟圆通
          // }
          dataArr.push({
            documentID: this.waybillNO,
            contents: [
              //电子面单部分
              {
                templateURL: this.waybillTemplateURL + '?id=' + item.id,
                signature: 'MD:kw4V6Gtj/7ZasLyotby+HA==',
                //电子面单数据
              },
            ],
          })
        })
        this.printTaskId = parseInt(1000 * Math.random())
        var request = {
          cmd: 'print',
          requestID: '12345678901234567890',
          version: '1.0',
          task: {
            taskID: '' + this.printTaskId,
            preview: false,
            printer: this.defaultPrinter,
            notifyMode: 'allInOne',
            previewType: 'pdf',
            documents: dataArr,
          },
        }
        return request
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
  .demo-tabs .custom-tabs-label span {
    vertical-align: super;
    margin-left: 4px;
  }
  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 12px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item {
    width: 200px; /* 设置宽度为200px */
  }
  .el-badge {
    width: 55px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }
  .demo-table-expand {
    font-size: 0;
    padding-left: 150px;
    box-sizing: border-box;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }
</style>
