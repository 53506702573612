<template>
  <!-- js代码错误实时提醒测试 -->
  <div>{{ chuzhixinjiayou.chuzhixinjiayou }}</div>
</template>

<script>
  export default {
    name: 'ErrorTest',
    data() {
      return {}
    },
  }
</script>
